.imgLogin {
  background-image: url("../../assets/images/pngs/login.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position-x: right;
  height: 100vh;
}

.loginConteudo {
  height: 100vh;
}

.btn-login {
  color: #fff !important;
  height: 70px !important;
  border-radius: 12px;
  width: 100%;
  max-width: 368px;
  background: #a3cd4d !important;
  border: none !important;
}
.textoMetilife {
  font: normal normal normal 15px/20px Roboto;
  color: #949fac;
}

.logoMetilife {
  max-width: 310px;
  width: 100%;
}

@media screen and (max-width: 1000px) {
  .modal {
    height: 150px;
    width: 250px;
  }
}

.divText {
  width: 100%;
  background-color: blue;
}