.modais {
  position: absolute;
  z-index: 1000;
  top: 50%;
  left: 50%;
  max-height: 93vh;
  max-width: 90vw;
  padding: 2vw;
  transform: translate(-50%, -50%);
  background: #ffffff;
  border: 1px solid #d8dfe8;
  border-radius: 10px;
  overflow-y: auto;
}
.modais::-webkit-scrollbar {
  width: 12px;
}

.modais::-webkit-scrollbar-thumb {
  background-color: #d8dfe8;
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.modais::-webkit-scrollbar-track {
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
}
@media (max-width: 768px) {
  .modais {
    max-width: 80vw;
  }
}

.modalCloseIcon {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.btn-modal {
  background: #a3cd4d !important;
  border: none;
  color: #fff;
  width: 368px !important;
  height: 70px;
  border-radius: 12px;
}
.btn-modal-Desabilitado {
  background: #d7d4d3;
  border: none;
  color: #ffffff;
  width: 368px !important;
  height: 70px;
  border-radius: 12px;
}

.btn-modal:hover {
  opacity: 0.7;
}
svg[data-testid="SearchIcon"] {
  /* Define a propriedade display como 'none' para ocultar o elemento */
  display: none !important;
}
/* Seletor do elemento com base no aria-label */
button[aria-label="Mostrar/Ocultar barra de pesquisa"] {
  /* Define a propriedade display como 'none' para ocultar o botão */
  display: none !important;
}
