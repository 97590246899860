.divFooter {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 0;
  width: 100%;
  height: 80px;
  background: #fbfbfb 0% 0% no-repeat padding-box;
  margin-top: 200px;
  z-index: 1000;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.226);
}
.divFooterEspaco {
  width: 100%;
  height: 80px;
  margin-top: 120px;
}
.textoFooter {
  display: inline-block;
  width: 100%;
  padding: 30px;
  font: normal normal normal 15px/20px Roboto;
  color: #949fac;
  transition: color 0.2s ease-in-out;
}
