.btMenu {
  position: relative;
  display: flex;
  align-items: center;
  height: 50px;
  width: 100%;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  font-weight: 700;
  color: #000; 
  border-bottom: 1px solid#00000026;
}
.btMenu:hover{
  cursor: pointer;
  color: #a3cd4d;  
  background-color: #F5F5F5;
}
.btMenuSemDrop {
  position: relative;
  display: flex;
  align-items: center;
  height: 50px;
  width: 100%;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  font-weight: 600;
  color: #000; 
  border-bottom: 1px solid#00000026;
}
.btMenuSemDrop:hover {
  background-color: #F5F5F5;
  cursor: pointer;
  color: #a3cd4d;  
}

.subMenu .btMenuSemDrop{
  background-color: rgba(50, 50, 50, 0.04) ;
  color: rgb(0, 0, 0);
}
.btMenuSemDropSelecionado {
  position: relative;
  display: flex;
  align-items: center;
  height: 50px;
  width: 100%;
  background-color: #a3cd4d;
  font-weight: 600;
  color: rgb(0, 0, 0); 
  border-bottom: 1px solid#00000026;
}

.logoMenu {
  margin-left: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 25px;
  min-height: 50px;
  min-width: 50px;
  overflow: hidden;
  padding-right: 15px;
}

.tituloMenu {
  margin-left: 10px;
  padding-right: 55px;
  min-width: 200px;
  font-weight: 600;
}
 
.logoMenuSemDrop {
  margin-left: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 25px;
  min-height: 50px;
  min-width: 50px;
  overflow: hidden;
  padding-right: 15px;
}
 
.logoMenuSemDropSelecionado svg {
  margin-left: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 25px;
  max-height: 50px;
  min-width: 50px;
  overflow: hidden;
  padding-right: 15px;
  color: white !important;
}

.tituloMenuSemDropSelecionado {
  margin-left: 10px;
  padding-right: 55px;
  min-width: 200px;
  color: #ffffff;
}
.tituloMenuSemDrop {
  margin-left: 10px;
  padding-right: 55px;
  min-width: 200px;
}
 