.imgMenu {
  z-index: 1;
  left: 0;
  width: 253px;
  transition: all 1s ease-in-out;
}


.imgMenuReducao {
  position: relative;
  left: 56px;
  width: 135px;
  transition: all 1s ease-in-out;
}

.divLogo{
  position: relative;
  right: 2px;
  min-height: 200px;
  /* margin-bottom: 2px; */
  width: 100%;
  background-color: #fff !important;
  border-bottom: 1px solid#00000026;
}

.navBar {
  float: left;
  background-color: #FFFFFF;
  width: 44px;
  overflow: hidden;
  transition: all 1s ease-in-out;
  border-top: 1px solid #1C2D4C;
}

.fundoMenu {
  position: fixed;
  background-color: rgb(255, 254, 254);
  height: 100%;
  width: 44px;
  box-shadow: 2px 2px 7px #00000026;
  transition: all 1s ease-in-out;
}

.mostrarNavBar {
  width: 300px;
}
/* 
@media screen and (max-width: 630px) {
  .mostrarNavBar {
    width: 44px;
  }
  .imgMenu {
    position: relative;
    left: 56px;
    width: 135px;
    transition: all 1s ease-in-out;
  }
  
} */

.btMenuSair {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 44px;
  background-color: #FF4136;
}

.btMenuSair:hover {
  background-color: #FFAEB9;
}

