@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background-color: #FFFFFF;
  font-family: Montserrat;
}
.pointer {
  cursor: pointer !important;
}
.row {
  padding: 0;
  margin: 0;
}
a {
  text-decoration: none !important;
  list-style: none !important;
}

.page {
  display: flex;
  flex-direction: column;
  padding: 50px;
  margin: 0;
}

.bordaTexto {
  border-bottom: 7px solid #008ad5;
  width: 100%;
  margin-left: 15px;
  margin-top: 15px;
  max-width: 100px;
}

/* span {
color:  #008ad5 !important;
} */
.botaoPrincipal {
  width: 100% !important;
  height: 60px !important;
  background: #a3cd4d 0% 0% no-repeat padding-box;
  border: 1px solid #a3cd4d;
  border-radius: 12px;
  color: #ffffff;
  font-size: 13px;
  font-weight: 600;
}

.botaoSecundario {
  width: 100% !important;
  height: 60px !important;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #000000;
  border-radius: 12px;
  color: #000000;
    font-family: 'Roboto', sans-serif;
  font-weight: bold;
  font-size: 13px;
}

.botaoDetalhe {
  width: 100% !important;
  height: 50px !important;
  background: #a3cd4d 0% 0% no-repeat padding-box;
  border: 1px solid #a3cd4d;
  border-radius: 12px;
  color: #ffffff;
  font-size: 13px;
  font-weight: 600;
}

.botaoDetalheSecundario {
  width: 100% !important;
  height: 50px !important;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #000000;
  border-radius: 12px;
  color: #000000;
    font-family: 'Roboto', sans-serif;
  font-weight: bold;
  font-size: 13px;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  color: #1C2D4C;
  font-family: 'Montserrat', sans-serif;
  font-weight: normal;
  font-size: 26px;
}

.aba {
  border-bottom: 3px solid #1ecfb4;
}
strong {
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  color: #1C2D4C;
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
  
  transition: background-color 600000s 0s, color 600000s 0s;

}

@media screen and (max-width: 1000px) {
  .desktop {
    display: none !important;
  }
  .mobile {
    display: flex !important;
  }

}
@media screen and (max-width: 1000px) {
  .imgLoginNone {
    display: none !important;
  }

}

@media screen and (max-height:520px) {
  .imgLoginNone {
    display: none !important;
  }

}

.mobile {
  display: none;
}
.desktop {
}
textarea:focus,
input:focus,
input:active,
select:focus{
  border-color: none !important;
  box-shadow: none !important;
}
/* MuiOutlinedInput-root MuiInputBase-colorPrimary MuiInputBase-fullWidth MuiInputBase-formControl MuiInputBase-sizeSmall css-md26zr-MuiInputBase-root-MuiOutlinedInput-root" */




* {
  /* background-color: rgba(0, 0, 255, 0.144) !important; */
}

input:disabled {
  font-weight: 900;
}

label {
  color: #000 !important;
}

.btn-ativado {
  border-bottom: solid 5px #ffd300 !important;
}

input,
textarea,
input:focus,
input:active {
  border-radius: 5px !important;
}

.btn {
  width: 100% !important;
  height: 100% !important;
}

.btn-principal {
  color: #fff !important;
  background: #1ecfb4 !important;
  border: none !important;
}
svg {
  color:  #A3CD4D !important;
}
.btn-primary {
  color: #fff !important;
  background: #A3CD4D !important;
  border: none !important;
  width: 100% !important;
  max-width: 368px;
  height: 70px !important;
}

.btn-secondary {
  color: #fff !important;
  background: #fb3030 !important;
  border: none !important;
}

.btn-voltar {
  color: #fff !important;
  background: #343434 !important;
  border: none !important;
}

td,
th {
  padding: 0 20px;
}

table {
  border-collapse: collapse;

  width: 100%;
}

table th,
tbody,
tr {
  border-bottom: 1px solid  #ddd;
  /* background-color:; */
  padding: 8px;
}

table tr:hover {
  background-color: #ddd;
}

table th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #FFFFFF;
  color: #171717;
}